<template>
  <b-card-code title="Modifier Filière">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du Filière </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="filieres"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Diplôme"
              label-for="Diplôme"
            >
              <validation-provider
                #default="{ errors }"
                name="Diplôme"
                rules="required"
              >
                <v-select
                  v-model="filieres.diploma"
                  :clearable="false"
                  :options="diplomas"
                  label="name"
                  placeholder="Diplôme"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Département"
              label-for="Département"
            >
              <validation-provider
                #default="{ errors }"
                name="Département"
                rules="required"
              >
                <v-select
                  v-model="filieres.department"
                  :clearable="false"
                  :options="departements"
                  label="name"
                  placeholder="Département"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Libellé Abrégé"
              label-for="Libellé Abrégé"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé Abrégé"
                rules="required"
              >
                <b-form-input
                  v-model="filieres.abbreviated_label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé Abrégé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Libellé"
              label-for="Libellé"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé"
                rules="required"
              >
                <b-form-input
                  v-model="filieres.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Libellé en Arabe"
              label-for="Libellé en Arabe"
            >
              <validation-provider
                #default="{ errors }"
                name="Libellé en Arabe"
                rules="required"
              >
                <b-form-input
                  v-model="filieres.label_arabe"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé en Arabe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5>Active</h5>
              <b-form-checkbox
                v-model="filieres.is_active"
                name="check-button"
                switch
                inline
                @click="filieres.is_active = true"
              >
                Active
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5>Admet Parcous</h5>
              <b-form-checkbox
                v-model="filieres.admits_course"
                name="check-button"
                switch
                inline
                @click="filieres.admits_course = true"
              >
                Admet Parcous
              </b-form-checkbox>
            </b-form-group>

          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editFiliere"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      password: '',
      filieres: {},
      required,
      categories: ['licence', 'master', 'cycle préparatoire', 'cycle ingénieur'],
      departements: [],
      diplomas: [],
    }
  },
  created() {
    this.getDiplomas()
    this.getDepartements()
    this.getFilieres()
  },
  methods: {
    async getDiplomas() {
      this.load = 'true'
      const response = await axios.get('/api/diplomas/')
      this.diplomas = response.data
      this.load = 'false'
    },
    async getDepartements() {
      this.load = 'true'
      const response = await axios.get('/api/departments/')
      this.departements = response.data
      this.load = 'false'
    },
    async getFilieres() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/filieres/detail/${id}`)
      this.filieres = response.data
      this.load = 'false'
    },
    async editFiliere() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/filieres/update/${id}/`,
                {
                  abbreviated_label: this.filieres.abbreviated_label,
                  label: this.filieres.label,
                  label_arabe: this.filieres.label_arabe,
                  diploma: this.filieres.diploma.id,
                  department: this.filieres.department.id,
                  admits_course: this.filieres.admits_course,
                  is_active: this.filieres.is_active,
                },
              )
            this.$router.push('/filieres')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Filieres Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
